import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Provider } from 'react-redux'
import get from 'lodash/get'
import { useEffect } from 'react'
import dynamic from 'next/dynamic'

import 'styles/index.scss'
import 'common/icons'
import { generateStore } from 'init'
import api from 'api'
import LanguageManager from 'common/language/LanguageManager'
import { BannerProvider } from 'hocs/withMainBannerHOC'
import getCookie from 'common/utils/getCookie'
import Script from 'next/script'
import Head from 'next/head'
import ApiError from 'components/ApiError'
import ensureLiveChatLoaded from 'common/utils/ensureLiveChatLoaded'


const CookieBanner = dynamic(() => import('common/widgets/CookieBanner'), { ssr: false })

App.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  pageProps: PropTypes.object,
  router: PropTypes.object.isRequired,
}

App.defaultProps = {
  pageProps: {},
}

function App({ Component, pageProps, router }) {
  const { locale = 'de', asPath } = router
  const store = generateStore(pageProps.initialReduxStore || {})
  useEffect(() => {
    window.addEventListener('popstate', function() {
      location.reload()
    }, false)
  }, [])

  useEffect(() => {
    const timeoutID = setTimeout(() => ensureLiveChatLoaded()
      .then(() => window.LiveChatWidget.init()), process.env.CHAT_INIT_TIMEOUT * 1000)
    return () => {
      clearTimeout(timeoutID)
    }
  }, [])
  const language = locale === 'default' ? 'de' : locale
  if(typeof window !== 'undefined') {
    api.interceptors.request.use({
      onSuccess: function(request) {
        if(request.body instanceof FormData) {
          return { ...request, headers: { ...request.headers, 'Accept-Language': language || 'de', 'X-CSRFToken': getCookie().bo_c } }
        }
        return { ...request, headers: { ...request.headers, 'Accept-Language': language || 'de', 'Content-Type': 'application/json; charset=UTF-8' } }
      },
    })
  }
  return (
    <>
      <Head>
        <link rel="icon" href={`${process.env.BACKEND_URL}/favicon.ico`} sizes="any" />
        <link rel="icon" href={`${process.env.BACKEND_URL}/s/favicon_icons/icon.svg`} type="image/svg+xml" />
        <link rel="apple-touch-icon" href={`${process.env.BACKEND_URL}/s/favicon_icons/apple-touch-icon.png`} />
        <link rel="manifest" href={`${process.env.BACKEND_URL}/s/favicon_icons/manifest.webmanifest`} />
        <script>window.dataLayer = window.dataLayer || []</script>
      </Head>
      <Provider store={store}>
        <LanguageManager data={get(pageProps, 'initialReduxStore.translations.data', {})} locale={language}>
          <ApiError />
          <BannerProvider>
            <Component {...pageProps} key={asPath.split('?')[0]} />
          </BannerProvider>
          <CookieBanner />
        </LanguageManager>
        <Script src="/scripts/live-chat.js" />
      </Provider>
    </>
  )
}

export default App
