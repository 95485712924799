import { API } from '@ds-frontend/api'
import { QueryParams } from '@ds-frontend/queryParams'


export const QS = new QueryParams()

const api = new API({
  baseURL: (typeof window === 'undefined') ? `${process.env.PROXY_URL}/` : '/',
  queryFuntion: QS.buildQueryParams,
})

export default api
