export default function ensureLiveChatLoaded() {
  const start = Date.now()
  return new Promise(waitForChat)

  function waitForChat(resolve, reject) {
    if(window?.LiveChatWidget?.init) {
      resolve()
    } else if((Date.now() - start) >= 10000) {
      reject(new Error('Chat Load Timeout'))
    } else {
      setTimeout(() => waitForChat(resolve, reject), 200)
    }
  }
}
