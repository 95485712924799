import { connect } from 'react-redux'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useMemo, useCallback, useState, createContext, useEffect } from 'react'


export const BannerContext = createContext({})

Provider.propTypes = {
  data: PropTypes.object,
  children: PropTypes.node.isRequired,
}

Provider.defaultProps = {
  data: {},
}

function Provider({ data, children }) {
  const isDefaultBannerVisible = useMemo(() =>
    (data.title || data.content) && typeof sessionStorage !== 'undefined' && sessionStorage.getItem('already_shown') !== data.updated,
  [data])
  const [ isBannerVisible, setVisible ] = useState(false)
  const toggleBanner = useCallback(() => {
    setVisible(!isBannerVisible)
    sessionStorage.setItem('already_shown', data.updated)
  }, [isBannerVisible])
  const [ headerHeight, setHeaderHeight ] = useState(0)
  useEffect(() => {
    if(typeof document !== 'undefined') {
      setHeaderHeight(document.getElementById('header') ? document.getElementById('header').offsetHeight : 0)
    }
  }, [isBannerVisible, setHeaderHeight])
  useEffect(() => {
    setTimeout(() => {
      setVisible(isDefaultBannerVisible)
    }, 100)
  }, [isDefaultBannerVisible, setVisible])
  return (
    <BannerContext.Provider
      value={{
        toggleBanner,
        headerHeight,
        isBannerVisible,
        bannerData: data,
      }}
    >
      {children}
    </BannerContext.Provider>
  )
}

export const BannerProvider = connect(state => ({
  data: get(state, 'cmsInfo.data.banner_settings') || {},
}))(Provider)

export default function withBannerHOC(Component) {
  return function(props) {
    return (
      <BannerContext.Consumer>
        {(state) => <Component {...props} {...state} />}
      </BannerContext.Consumer>
    )
  }
}
