import React, { createContext } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/de'


export const LanguageContext = createContext()

LanguageManager.propTypes = {
  data: PropTypes.object,
  translationKey: PropTypes.string,
  locale: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

LanguageManager.defaultProps = {
  data: {},
  translationKey: 'catalog',
}

export default function LanguageManager({
  data,
  locale,
  translationKey,
  children,
}) {
  const translations = data
  const gettext = (text = '') =>
    get(translations, [translationKey, text]) || text
  const pgettext = (id, text) => {
    const message = `${text} ${id}`
    return gettext(message)
  }
  const ngettext = (singular, plural, count) => {
    const translation = get(translations, [translationKey, singular])
    if(translation === undefined) {
      return count === 1 ? singular : plural
    }
    return count === 1 ? translation[0] : translation[1]
  }
  const npgettext = (id, singular, plural, count) => {
    const selector = `${singular} ${id}`
    const translation = get(translations, [translationKey, selector])
    if(translation === undefined) {
      return count === 1 ? singular : plural
    }
    return count === 1 ? translation[0] : translation[1]
  }
  const languageCode = locale === 'default' ? 'de' : locale
  moment.locale(languageCode)
  return (
    <LanguageContext.Provider
      value={{
        languageCode,
        translations,
        gettext,
        pgettext,
        ngettext,
        npgettext,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
