import { useEffect } from 'react'
import API from 'api'
import { notification } from 'antd'
import { useTranslations } from 'common/language'
import get from 'lodash/get'


export default function ApiError() {
  const { gettext } = useTranslations()
  useEffect(() => {
    API.interceptors.response.use({
      onError: function({ data, response }) {
        if(get(response, 'status') === 500) {
          // eslint-disable-next-line no-throw-literal
          throw { data, response }
        }
        if(get(data, 'non_field_errors') && !(typeof window === 'undefined')) {
          notification.error({
            message: gettext('Error'),
            description: Array.isArray(data.non_field_errors) ? data.non_field_errors.join('. ') : data.non_field_errors,
            duration: 5,
          })
          return { data, response }
        }

        // eslint-disable-next-line no-throw-literal
        throw { ...data, response }
      },
    })
  }, [])
  return null
}
