import { useContext } from 'react'

import { LanguageContext } from './LanguageManager'


export function withTranslations(ChildComponent) {
  return function(props) {
    return (
      <LanguageContext.Consumer>
        {({ translations, ...rest }) => <ChildComponent {...props} {...rest} />}
      </LanguageContext.Consumer>
    )
  }
}

export function useTranslations() {
  // eslint-disable-next-line no-unused-vars
  const { translations, ...translationData } = useContext(LanguageContext)
  return translationData
}

export function gettext(text = '') {
  return (
    <LanguageContext.Consumer>
      {({ gettext }) => gettext(text)}
    </LanguageContext.Consumer>
  )
}

export function pgettext(id = '', text = '') {
  return (
    <LanguageContext.Consumer>
      {({ pgettext }) => pgettext(id, text)}
    </LanguageContext.Consumer>
  )
}

export function ngettext(singular = '', plural = '', count) {
  return (
    <LanguageContext.Consumer>
      {({ ngettext }) => ngettext(singular, plural, count)}
    </LanguageContext.Consumer>
  )
}

export function npgettext(id = '', singular = '', plural = '', count) {
  return (
    <LanguageContext.Consumer>
      {({ npgettext }) => npgettext(id, singular, plural, count)}
    </LanguageContext.Consumer>
  )
}

export function interpolate(message, obj, named) {
  if(named) {
    return message.replace(/%\(\w+\)s/g, function(match) {
      return String(obj[match.slice(2, -2)])
    })
  } else {
    if(message.replace) {
      return message.replace(/%s/g, function(match) {
        return String(obj.shift())
      })
    }
  }
}
