import API from './api'
import { resourcesReducer } from '@ds-frontend/resource'
// import { cacheMiddleware, persistReducer } from '@ds-frontend/cache'
import { promisableActionMiddleware, composeReducers, combineReducers } from '@ds-frontend/redux-helpers'
import { createStore, applyMiddleware } from 'redux'
import { reducers } from 'store'
import authMiddleware from 'common/session/authMiddleware'
// import omit from 'lodash/omit'
import { composeWithDevTools } from 'redux-devtools-extension'


global.atob = require('atob')


// support for redux dev tools
const compose = composeWithDevTools({
  name: process.env.APP_NAME,
})

const initialState = {}

function rootReducer(state, action) {
  return action.type === 'SSR_CLEAN_STORE' ? initialState : state
}


const generateStore = (initialReduxState) => {
  return createStore(
    composeReducers(
      {},
      rootReducer,
      combineReducers(reducers),
      resourcesReducer,
    ),
    initialReduxState || {},
    compose(
      applyMiddleware(...[
        process.env.SSR ? null : authMiddleware,
        promisableActionMiddleware({ API }),
      ].filter(Boolean))
    )
  )
}

export {
  generateStore,
}
